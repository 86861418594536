/* In fonts.css */
@font-face {
  font-family: "Bebas Neue";
  src: url("./fonts/BebasNeue Bold.otf") format("opentype");
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("./fonts/BebasNeue Book.otf") format("opentype");
  font-weight: 400; /* Book */
  font-style: normal;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("./fonts/BebasNeue Light.otf") format("opentype");
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("./fonts/BebasNeue Regular.otf") format("opentype");
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("./fonts/BebasNeue Thin.otf") format("opentype");
  font-weight: 100; /* Thin */
  font-style: normal;
}
/* In fonts.css */
@font-face {
  font-family: "Interstate";
  src: url("./fonts/InterstateBlack.otf") format("opentype");
  font-weight: 900; /* Black */
  font-style: normal;
}

@font-face {
  font-family: "Interstate";
  src: url("./fonts/InterstateBold.otf") format("opentype");
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: "Interstate";
  src: url("./fonts/InterstateExtraLight.otf") format("opentype");
  font-weight: 200; /* Extra Light */
  font-style: normal;
}

@font-face {
  font-family: "Interstate";
  src: url("./fonts/InterstateHairline.otf") format("opentype");
  font-weight: 100; /* Hairline */
  font-style: normal;
}

@font-face {
  font-family: "Interstate";
  src: url("./fonts/InterstateLight.otf") format("opentype");
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: "Interstate";
  src: url("./fonts/InterstateBlack.otf") format("opentype");
  font-weight: 900; /* Mono Black */
  font-style: normal;
}

@font-face {
  font-family: "Interstate";
  src: url("./fonts/InterstateBold.otf") format("opentype");
  font-weight: 700; /* Mono Bold */
  font-style: normal;
}

@font-face {
  font-family: "Interstate";
  src: url("./fonts/InterstateRegular.otf") format("opentype");
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: "Interstate";
  src: url("./fonts/InterstateThin.otf") format("opentype");
  font-weight: 100; /* Thin */
  font-style: normal;
}

@font-face {
  font-family: "Interstate";
  src: url("./fonts/InterstateUltraBlack.otf") format("opentype");
  font-weight: 950; /* Ultra Black */
  font-style: normal;
}
